<!--  -->
<template>
<div class="filePage">
    <div class="protocol-head">
        <div class="protocol-head-container flex-vc flex">
            <a href="/" class="flex-vc flex">
                <div class="logo">
                    <img src="../../assets/img/default/upupoo.png">
                </div>
                <span class="text">upupoo</span>
            </a>
        </div>
    </div>
    <div class="protocol-layout-body">
        <h3>《UPUPOO会员服务协议》</h3>
        <p>更新日期：2024年1月22日</p>
        <p>欢迎您使用UPUPOO会员服务！</p>
        <p>为使用UPUPOO会员服务（简称为：本服务），请您仔细阅读、理解并遵守《UPUPOO会员服务协议》（简称为：本协议）。</p>
        <p class="protocal-title-bold">本协议由您（以下亦称“会员”）和UPUPOO进行缔结，对双方具有同等法律效力。UPUPOO建议您仔细阅读本协议的全部内容，尤其是以加粗形式展示的，与您的权益（可能）存在重大关系的条款，请您留意重点阅读。请您务必在确保您已经理解、接受了本协议条款的前提下，继续使用UPUPOO会员服务。</p>
        <p class="protocal-title-bold">如果您不同意本协议的任一或全部条款内容，请不要以接受本服务的形式（包括但不限于支付行为/接受赠与、或完成了成为会员的全部程序而在此过程中未向UPUPOO提出关于本协议的任何异议）进行下一步操作或使用本服务。当您以确认形式进行下一步操作或使用UPUPOO会员服务时，即表示您与UPUPOO已达成协议关系，您自愿接受本协议并遵守本协议项下的全部约定。</p>
        <p class="protocal-title-bold">UPUPOO有权变更本协议内容，一旦本协议内容发生变更的，UPUPOO将在相应页面、站内信或以其他合理方式进行通知，请您仔细阅读。如果您不同意变更的内容的，您可以选择停止使用UPUPOO会员服务。如您继续使用UPUPOO会员服务的，则视为您已经同意变更的全部内容。更新后的协议自文首更新之日起生效。</p>
        <p>若您是未成年人，请您在监护人监护、指导下阅读并决定是否同意本协议。</p>
        <p class="protocal-title-bold">一、会员权益及额外付费相关</p>
        <p class="protocal-title-bold">1. 【UPUPOO会员】</p>
        <p> 是指完成了成为会员的所有程序，且在遵守本协议的前提下，在会员时长有效期内享受UPUPOO提供的会员服务的自然人。</p>
        <p class="protocal-title-bold">1.1.【UPUPOO会员权益】</p>
        <p>UPUPOO现有VIP会员和SVIP会员两种模式。</p>
        <p>VIP会员权益：可使用非签约作者定价销售的动态壁纸和系统美化（鼠标、图标、任务栏、开始菜单）功能，【组件】页可免费下载使用。本协议项下的会员权益名称与页面的说明不一致的，不影响您所实际享受的会员权益的内容。</p>
        <p>SVIP会员权益：包含VIP会员的所有权益，同时可以在UPUPOO客户端中使用签约作者在【资源区】售卖的所有作品资源。</p>
        <p class="protocal-title-bold">UPUPOO仅提供动静态壁纸、电脑美化、软件内组件的功能应用和资源的使用权，为保证所有上传者的权益，资源源文件会被加密，任何破解、复制、转发行为不被允许。</p>
        <p>2.【设备及系统差异】</p>
        <p>您成为会员后，可能会因您使用的软件版本、设备、操作系统等不同以及其他第三方原因导致实际可使用的具体权益或服务内容有差别，由此可能给您带来的不便，您表示理解且不予追究或豁免UPUPOO的相关责任。UPUPOO建议您可以通过升级应用程序或操作系统版本、更换使用设备等方式来尝试解决。</p>
        <p class="protocal-title-bold">3.【应用内付费】</p>
        <p class="protocal-title-bold">VIP会员在会员有效期内可以使用UPUPOO所有VIP会员功能，【资源区】中部分内容需要额外付费后方可使用。</p>
        <p class="protocal-title-bold">3.1【付费内容】</p>
        <p class="protocal-title-bold">签约作者上传的动态壁纸和电脑美化项，版权归签约作者所有，由签约作者定价销售。该类型的资源需要用户充值喵币后购买或开通SVIP使用。</p>
        <p>4.【活动参与】</p>
        <p>在会员服务期限内，您有权选择参加由UPUPOO组织的会员活动并享有由UPUPOO提供的各项优惠。具体活动及优惠的内容及规则以UPUPOO实际提供为准。</p>
        <p>5.【使用规范】</p>
        <p>您在获取、使用UPUPOO会员服务(包括参与活动)时，应遵循UPUPOO官方渠道公布的流程、平台规范等：</p>
        <P>5.1不得通过未经UPUPOO授权或公布的途径、方式、渠道非法获取UPUPOO会员服务（包括但不限于部分或全部会员权益、会员账号）；</P>
        <p>5.2不得通过不正当手段或以违反诚实信用原则的方式（如利用规则漏洞、利用系统漏洞、滥用会员身份、黑色产业、投机等违背UPUPOO提供服务/举办活动的初衷的方式）参与活动或者获取福利。</p>
        <p class="protocal-title-bold">若您违反上述约定，则UPUPOO有权对您所获得的福利作出删除、取消、清零等处理，且有权终止向您提供服务，由此造成的全部损失由您自行承担。</p>
        <p>6.【服务期限】</p>
        <p>6.1 您的会员服务的服务期限以您自行选择并支付相应会员费用对应的服务期限为准，自您成UPUPOO会员之时起算，您可以通过登录UPUPOO会员中心免费查询。该期限不因您未使用而延长。当会员服务到期后，UPUPOO将停止继续向您提供会员服务。</p>
        <p>如您开通的会员服务是终身会员，在UPUPOO存续期间一直享有会员服务，无会员到期时限。</p>
        <p>6.2 请您理解，因互联网服务的特殊性，会员服务期限中包含UPUPOO解决故障、服务器维修、调整、升级等或因第三方侵权处理所需用的合理时间，但UPUPOO会尽可能将影响降至最低。</p>
        <p class="protocal-title-bold">二、收费及退订</p>
        <p>1.【收费方式】</p>
        <p>UPUPOO会员服务为收费服务，您可通过UPUPOO实际支持的付费方式完成UPUPOO会员费用的支付，如支付宝、微信等。</p>
        <p>2.【费用退还】</p>
        <p>UPUPOO会员服务系网络商品和虚拟商品，会员费用是您所购买的会员服务所对应的网络商品价格，而非预付款或者存款、定金、储蓄卡等性质，会员服务一经购买退款需按天扣除服务费。</p>
        <p>VIP会员退款：购买后当天申请退款，扣除5元基础服务费，从购买后第2天起申请退款，每天消耗0.5元服务费，当购买VIP会员时的付费金额全消耗完后，即不可退款。</p>
        <p>SVIP会员退款：购买后当天申请退款，扣除15元基础服务费，从购买后第2天起申请退款，每天消耗1元服务费，当购买SVIP会员时的付费金额全消耗完后，即不可退款。</p>
        <p>注意！限时体验会员不支持退款！</p>
        <p>UPUPOO在此特别提醒您，您在购买会员服务之前应仔细核对账号信息、购买的服务内容、价格、服务期限等信息。</p>
        <p class="protocal-title-bold">3.【收费标准、方式的变更】</p>
        <p class="protocal-title-bold">UPUPOO会员服务的收费方式、收费标准由UPUPOO根据公司的运营成本、运营策略等综合考虑后独立决定（调整包括但不限于促销、涨价等），并在相关的产品服务宣传及支付页面向您展示；若您在购买和续费时，相关收费方式发生变化的，以UPUPOO实际支持的收费方式为准；相关服务的价格发生了调整的，应以UPUPOO平台上公示的现时有效的价格为准。</p>
        <p class="protocal-title-bold">三、行为规范和违约处理</p>
        <p class="protocal-title-bold">1. 在您使用UPUPOO会员服务的过程中，不得存在以下行为：</p>
        <p>1.1  未经UPUPOO明确授权，通过技术手段对服务内容、服务期限、消费金额、交易状态等信息进行修改；</p>
        <p>1.2  将会员服务通过非UPUPOO明确授权的方式（包括但不限于借用、租用、售卖、分享、转让）提供他人使用；</p>
        <p>1.3  未经UPUPOO明确授权，将会员服务提供的壁纸、蜂巢收纳、鼠标、图标等进行复制、下载、上传、修改、编目排序、翻译、发行、开发、转让、销售、传播、进行商业开发或推广等；</p>
        <p>1.4  对UPUPOO用于保护会员服务的任何安全措施技术进行破解、更改、反操作、篡改或其他破坏；</p>
        <p>1.5  未经UPUPOO明确授权，采用收费或免费的方式，在任何公开场合全部或部分展示UPUPOO会员服务内容（但如您的上述行为不构成侵权的除外）；</p>
        <p>1.6  其他未经UPUPOO明示授权许可或违反本协议、法律法规或监管政策、侵犯第三方或UPUPOO合法权益的行为。</p>
        <p class="protocal-title-bold">2.您知悉并同意，如您存在任何违反国家法律法规或监管政策、违反本协议或有损UPUPOO或/及其关联公司的声誉、利益的行为的，UPUPOO有权采取以下一项或多项处理措施：</p>
        <p class="protocal-title-bold">2.1  如本协议对此行为有单独条款约定处理方式的，按照该条款处理；</p>
        <p class="protocal-title-bold">2.2  采取一种或多种措施制止您的行为及行为产生的后果，如删除/屏蔽相关链接或内容、限制/取消您的账号/账户使用权限等；</p>
        <p class="protocal-title-bold">2.3  中断或终止部分或全部会员服务，且您已交纳的会员服务费用将不予退还且不获得任何形式的补偿/赔偿；</p>
        <p class="protocal-title-bold">2.4  如您的行为使UPUPOO或/及其关联公司遭受任何损失的，您应当承担全部损失赔偿责任并在UPUPOO要求的时限内完成费用支付。</p>
        <p>四、服务的中断和终止 </p>
        <p>1.   本服务的中断或终止包括如下情况：</p>
        <p>1.1  您主动提出要求的；</p>
        <p>1.2  您存在任何违反国家法律法规或监管政策、违反本协议或有损UPUPOO或/及其关联公司的声誉、利益的行为的；</p>
        <p>1.3  UPUPOO根据法律法规、监管政策的规定或有权机关的要求；</p>
        <p>1.4  UPUPOO为维护账号与系统安全等紧急情况之需要；</p>
        <p>1.5  不可抗力（不可抗力是指：不能预见、不能避免且不能克服的客观情况，包括国家法律、法规、政策及国家机关的命令及其他政府行为或者其它的诸如台风、洪水、地震、海啸、战争、罢工等）；</p>
        <p>1.6  其他UPUPOO无法抗拒的情况。</p>
        <p>2.   当发生前述终止的情况时，UPUPOO将根据法律法规的规定与您就本服务的中断或终止事宜进行处理。</p>
        <p>五、您的个人信息保护</p>
        <p>1.   UPUPOO深知个人信息对您的重要性，因此UPUPOO非常重视保护您的个人信息，亦将您的个人信息以高度审慎的义务对待和处理。在您使用UPUPOO会员服务的过程中，UPUPOO将采用相关技术措施及其他安全措施来保护您的个人信息。</p>
        <p>2.   未成年人保护</p>
        <p>UPUPOO非常注重未成年人的保护。</p>
        <p>2.1  若您为未成年人，应在监护人监护、指导下阅读本协议，并且使用UPUPOO会员服务已经得到监护人的同意。</p>
        <p class="protocal-title-bold">2.2  监护人应指导子女上网应该注意的安全问题，防患于未然。若监护人同意未成年人使用会员服务，必须以监护人名义申请消费，并对未成年人使用会员服务进行正确引导、监督。未成年人使用会员服务，以及行使和履行本协议项下的权利和义务即视为已获得了监护人的认可。</p>
        <p>2.3  UPUPOO提醒未成年人在使用会员服务时，要善于网上学习，认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活。</p>
        <p class="protocal-title-bold">六、通知 </p>
        <p>为便于您获知与本协议和会员服务相关的信息，您同意UPUPOO有权通过网页公示、页面提示、弹窗、消息通知、公众号通知、站内信、您预留的联系方式（如手机短信、电子邮件等）等一种或多种方式进行通知，该通知自UPUPOO发送之时视为已成功送达您。如多种通知方式并存的，则送达时间以上述方式中最早发送之时为准。此类通知的内容或将对您产生重大有利或不利影响，请您务必确保联系方式为有效并请及时关注相应通知。</p>
        <p class="protocal-title-bold">七、联系UPUPOO</p>
        <p>如您对本协议或使用会员服务的过程中有任何问题（包括问题咨询、投诉等），UPUPOO专门为您提供了多种反馈通道，希望为您提供满意的解决方案：</p>
        <p>在线客服/其他在线意见反馈通道：您可与UPUPOO平台上菜单栏页面的在线客服联系或者在线提交意见反馈；</p>
        <p> 邮件通道：upupoo@upupoo.com</p>
        <p>其他方式：UPUPOO提供的其他反馈通道</p>
        <p>我们会在收到您的反馈后尽快向您答复。</p>
        <p class="protocal-title-bold">八、   其他</p>
        <p>1.   本协议的生效、履行、解释及争议的解决均适用中华人民共和国法律。</p>
        <p class="protocal-title-bold">2.   如就本协议的签订、履行等发生任何争议的，双方应尽量友好协商解决；协商不成时，任何一方均可向被告住所地享有管辖权的人民法院提起诉讼。</p>
        <p>3.   如本协议因与中华人民共和国现行法律相抵触而导致部分无效的，不影响协议的其他部分的效力。</p>
        <p>4.   本协议的标题仅为方便及阅读而设，并不影响正文中任何条款的含义或解释。</p>
        <p style="text-align: right;">UPUPOO</p>
        <p style="text-align: right;">武汉小咪网络科技有限公司</p>

    </div>
</div>
</template>

<script>
export default {
    name: '',
    components: {},
    data() {
        return {

        };
    },
    computed: {},
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.filePage {
  background: #fff;
  width: 100%;
  height: 100%;

  .h-bga {
    background: none;
    overflow-x: hidden;
  }

  .protocol-head {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(85, 85, 85, 0.1);
  }

  .protocol-head-container {
    width: 1080px;
    height: 60px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }

  .protocol-head-container a {
    display: flex;
    align-items: center;
  }

  .protocol-head-container .logo {
    width: 42px;
    height: 42px;
    line-height: 42px;
    margin-right: 10px;
    text-align: center;
    font-size: 0;
    display: inline-block;
  }

  .protocol-head-container img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }

  .protocol-head-container .text {
    font-size: 20px;
    color: #4a4a4a;
    line-height: 60px;
  }

  .protocol-layout-body {
    width: 1080px;
    margin: 22px auto 0;
    min-height: 886px;
    color: #000;
    font-size: 16px;
  }

  .protocol-layout-body h3 {
    padding: 4px 0 24px;
    font-size: 24px;
    border-bottom: 1px solid #e7e7eb;
    margin-bottom: 24px;
  }

  .protocol-layout-body p {
    margin-bottom: 20px;
    line-height: 24px;
  }

  .protocal-title-bold {
    font-weight: bold;
  }
}
</style>
